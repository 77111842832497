import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { Search, Calendar, User, Mail, Phone, MapPin, Eye, Plus, DollarSign, FileText } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'
import axiosApi from '../api/axiosApi'

export default function DealerDetails() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [dealer, setDealer] = useState(null)
    const [associates, setAssociates] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
        getDealerDetails()
        fetchAssociates()
    }, [id])


    // getDealerDetails
    const getDealerDetails = async () => {
        try {
            const response = await axiosApi.post(`/getDealerDetails?dealerId=${id}`)
            setDealer(response.data.data)
        } catch (error) {
            console.error('Error fetching dealer details:', error)
        }
    }


    const fetchAssociates = async () => {
        try {

            const response = await axiosApi.get(`/getAssociateByDealer?dealerId=${id}`)
            setAssociates(response.data.data)
        } catch (error) {
            console.error('Error fetching associates:', error)
        }
    }

    const filteredAssociates = associates.filter(associate =>
        associate.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!startDate || new Date(associate.createdAt) >= new Date(startDate)) &&
        (!endDate || new Date(associate.createdAt) <= new Date(endDate))
    )

    const handleAddAssociate = () => {
        navigate(`/dealers/${id}/add-associate`)
    }

    const pageVariants = {
        initial: { opacity: 0, y: 20 },
        in: { opacity: 1, y: 0 },
        out: { opacity: 0, y: -20 }
    }

    const pageTransition = {
        type: 'tween',
        ease: 'anticipate',
        duration: 0.5
    }

    if (!dealer) {
        return (
            <motion.div
                className="flex justify-center items-center h-screen"
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
            >
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-lime-500"></div>
            </motion.div>
        )
    }

    return (
        <motion.div
            className="bg-white shadow-lg rounded-lg overflow-hidden"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
        >
            <div className="px-6 py-4 bg-gradient-to-r from-lime-500 to-green-600 text-white">
                <h3 className="text-2xl font-bold">{dealer.name}</h3>
            </div>
            <div className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
                    <InfoCard icon={Mail} title="Email" value={dealer.email} />
                    <InfoCard icon={Phone} title="Mobile" value={dealer.mobile} />
                    <InfoCard icon={MapPin} title="Location" value={`${dealer.city}, ${dealer.state}`} />
                    <InfoCard icon={User} title="Username" value={dealer.username} />
                    <InfoCard icon={Calendar} title="Joined" value={new Date(dealer.createdAt).toLocaleDateString()} />
                    <InfoCard icon={User} title="Active Associates" value={associates.length} />
                </div>

                <div className="flex justify-between items-center mb-4">
                    <h4 className="text-lg font-semibold">Associates</h4>
                    <motion.button
                        onClick={handleAddAssociate}
                        className="bg-lime-600 text-white py-2 px-4 rounded-lg hover:bg-lime-700 transition duration-300 flex items-center"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Plus size={20} className="mr-2" />
                        Add Associate
                    </motion.button>
                </div>

                <div className="mb-6 flex space-x-4">
                    <div className="relative flex-1">
                        <Search className="absolute top-3 left-3 text-gray-400" size={20} />
                        <input
                            type="text"
                            placeholder="Search associates..."
                            className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <div className="relative">
                            <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="date"
                                className="pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="relative">
                            <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="date"
                                className="pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <AnimatePresence>
                    {filteredAssociates.map((associate) => (
                        <motion.div
                            key={associate.id}
                            className="bg-gray-50 rounded-lg p-4 mb-4 hover:shadow-md transition-shadow duration-300"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className="flex justify-between items-center">
                                <div>
                                    <div className="flex items-center mb-2">
                                        <User className="text-lime-600 mr-2" size={24} />
                                        <h5 className="text-lg font-semibold">{associate.name}</h5>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <p className="text-gray-600 flex items-center">
                                            <Mail className="mr-2" size={16} /> {associate.email}
                                        </p>
                                        <p className="text-gray-600 flex items-center">
                                            <Phone className="mr-2" size={16} /> {associate.mobile}
                                        </p>
                                        <p className="text-gray-600 flex items-center">
                                            <MapPin className="mr-2" size={16} /> {associate.city}, {associate.state}
                                        </p>
                                        <p className="text-gray-600 flex items-center">
                                            <Calendar className="mr-2" size={16} /> Joined: {new Date(associate.createdAt).toLocaleDateString()}
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={`/associates/${associate.id}/reports`}
                                    className="bg-lime-100 text-lime-600 font-semibold py-2 px-4 rounded-lg hover:bg-lime-200 transition duration-300 flex items-center"
                                >
                                    <Eye size={20} className="mr-2" />
                                    View Reports
                                </Link>
                            </div>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>
        </motion.div>
    )
}

function InfoCard({ icon: Icon, title, value }) {
    return (
        <motion.div
            className="bg-gray-50 p-4 rounded-lg flex items-center space-x-4"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
        >
            <Icon className="text-lime-500" size={24} />
            <div>
                <p className="text-sm text-gray-500">{title}</p>
                <p className="text-lg font-semibold">{value}</p>
            </div>
        </motion.div>
    )
}