import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosApi from '../api/axiosApi';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const refreshUser = useCallback(async () => {
        try {
            const response = await axiosApi.get('/me');
            setUser(response.data.data);
            // if (response.data.data.role === 'dealer') {
            //     navigate(`/dealers/${response.data.data.id}`);
            // }
        } catch (error) {
            console.error('Failed to refresh user:', error);
            logout();
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        refreshUser();
    }, [refreshUser]);

    const login = async (mobileNumber, password) => {
        setLoading(true);
        try {
            const response = await axiosApi.post('/login', { mobile: mobileNumber, password });
            console.log(response.data.data)
            const { token } = response.data.data;
            console.log(token)
            // token
            localStorage.setItem('tk', token);
            await refreshUser();
            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('tk');
        setUser(null);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading, refreshUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);