import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { User, Mail, Phone, MapPin, Home, Flag, Key, Loader, CheckCircle, Search, ChevronDown } from 'lucide-react'
import axiosApi from '../api/axiosApi'
import { useAuth } from '../contexts/AuthContext'

export default function AssociateRegistration() {
    const navigate = useNavigate()
    const { id } = useParams()
    const { user } = useAuth()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
        city: '',
        state: '',
        username: '',
        password: '',
        parent_id: id || (user.role === 'dealer' ? user.id : ''),
    })
    const [isLoading, setIsLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [dealers, setDealers] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        if (user.role === 'admin') {
            fetchDealers()
        }
    }, [user.role])

    const fetchDealers = async () => {
        try {
            const response = await axiosApi.get('/getAllUserByParent')
            const dealersData = response.data.data.filter(user => user.role === 'dealer')
            setDealers(dealersData) // Remove the admin option
        } catch (error) {
            console.error('Error fetching dealers:', error)
        }
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const registrationData = {
                ...formData,
                role: 'associate',
            }
            const response = await axiosApi.post('/register', registrationData)
            console.log('Associate registration successful:', response.data)
            setShowSuccessModal(true)
            setTimeout(() => {
                setShowSuccessModal(false)
                navigate(-1)
            }, 2000)
        } catch (error) {
            console.error('Error registering associate:', error)
            // Handle error (e.g., show error message to user)
        } finally {
            setIsLoading(false)
        }
    }

    const inputFields = [
        { name: 'name', icon: User, placeholder: 'Full Name' },
        { name: 'email', icon: Mail, placeholder: 'Email Address' },
        { name: 'mobile', icon: Phone, placeholder: 'Mobile Number' },
        { name: 'address', icon: MapPin, placeholder: 'Address' },
        { name: 'city', icon: Home, placeholder: 'City' },
        { name: 'state', icon: Flag, placeholder: 'State' },
        { name: 'username', icon: User, placeholder: 'Username' },
        { name: 'password', icon: Key, placeholder: 'Password', type: 'password' },
    ]

    const filteredDealers = dealers.filter(dealer =>
        dealer.name.toLowerCase().includes(searchTerm.toLowerCase())
    )

    return (
        <motion.div
            className="max-w-2xl mx-auto bg-white p-8 rounded-xl shadow-2xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <h2 className="text-3xl font-bold mb-6 text-center text-lime-600">Associate Registration</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">

                    {user.role === 'dealer' && (
                        <div className="relative col-span-2">
                            <User className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="text"
                                value={user.name}
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none bg-gray-100"
                                disabled
                            />
                        </div>
                    )}
                    {inputFields.map((field) => (
                        <div key={field.name} className="relative">
                            <field.icon className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type={field.type || 'text'}
                                name={field.name}
                                placeholder={field.placeholder}
                                required
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={formData[field.name]}
                                onChange={handleChange}
                            />
                        </div>
                    ))}

                    {user.role === 'admin' && (
                        <div className="relative col-span-2">
                            <label htmlFor="parent_id" className="block text-sm font-medium text-gray-700 mb-1">
                                Assign to Dealer
                            </label>
                            <div className="relative">
                                <select
                                    id="parent_id"
                                    name="parent_id"
                                    value={formData.parent_id}
                                    onChange={handleChange}
                                    className="w-full pl-3 pr-10 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500 appearance-none"
                                    required
                                >
                                    <option value="">Select a dealer</option>
                                    {filteredDealers.map((dealer) => (
                                        <option key={dealer.id} value={dealer.id}>
                                            {dealer.name}
                                        </option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <ChevronDown className="h-4 w-4" />
                                </div>
                            </div>
                            {formData.parent_id === "" && (
                                <p className="mt-1 text-sm text-red-600">Please select a dealer</p>
                            )}
                        </div>
                    )}
                </div>
                <motion.button
                    type="submit"
                    className="w-full bg-lime-600 text-white py-2 px-4 rounded-lg hover:bg-lime-700 transition duration-300 flex items-center justify-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <Loader className="animate-spin mr-2" size={20} />
                    ) : null}
                    {isLoading ? 'Registering...' : 'Register Associate'}
                </motion.button>
            </form>

            <AnimatePresence>
                {showSuccessModal && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white p-6 rounded-lg shadow-xl text-center"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                        >
                            <CheckCircle className="text-lime-600 w-16 h-16 mx-auto mb-4" />
                            <h3 className="text-2xl font-bold text-gray-800 mb-2">Registration Successful!</h3>
                            <p className="text-gray-600">The associate has been registered successfully.</p>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}