import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { User, Mail, Phone, MapPin, Home, Flag, Key, Loader, CheckCircle } from 'lucide-react'
import axiosApi from '../api/axiosApi'

export default function DealerRegistration() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
        city: '',
        state: '',
        username: '',
        password: '',
    })
    const [isLoading, setIsLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const registrationData = {
                ...formData,
                role: 'dealer',
                parent_id: 1,
            }
            const response = await axiosApi.post('/register', registrationData)
            console.log('Dealer registration successful:', response.data)
            setShowSuccessModal(true)
            setTimeout(() => {
                setShowSuccessModal(false)
                navigate('/dealers')
            }, 2000)
        } catch (error) {
            console.error('Error registering dealer:', error)
            // Handle error (e.g., show error message to user)
        } finally {
            setIsLoading(false)
        }
    }

    const inputFields = [
        { name: 'name', icon: User, placeholder: 'Full Name' },
        { name: 'email', icon: Mail, placeholder: 'Email Address' },
        { name: 'mobile', icon: Phone, placeholder: 'Mobile Number' },
        { name: 'address', icon: MapPin, placeholder: 'Address' },
        { name: 'city', icon: Home, placeholder: 'City' },
        { name: 'state', icon: Flag, placeholder: 'State' },
        { name: 'username', icon: User, placeholder: 'Username' },
        { name: 'password', icon: Key, placeholder: 'Password', type: 'password' },
    ]

    return (
        <motion.div
            className="max-w-2xl mx-auto bg-white p-8 rounded-xl shadow-2xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <h2 className="text-3xl font-bold mb-6 text-center text-lime-600">Dealer Registration</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    {inputFields.map((field) => (
                        <div key={field.name} className="relative">
                            <field.icon className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type={field.type || 'text'}
                                name={field.name}
                                placeholder={field.placeholder}
                                required
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={formData[field.name]}
                                onChange={handleChange}
                            />
                        </div>
                    ))}
                </div>
                <motion.button
                    type="submit"
                    className="w-full bg-lime-600 text-white py-2 px-4 rounded-lg hover:bg-lime-700 transition duration-300 flex items-center justify-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <Loader className="animate-spin mr-2" size={20} />
                    ) : null}
                    {isLoading ? 'Registering...' : 'Register Dealer'}
                </motion.button>
            </form>

            <AnimatePresence>
                {showSuccessModal && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white p-6 rounded-lg shadow-xl text-center"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                        >
                            <CheckCircle className="text-lime-600 w-16 h-16 mx-auto mb-4" />
                            <h3 className="text-2xl font-bold text-gray-800 mb-2">Registration Successful!</h3>
                            <p className="text-gray-600">The dealer has been registered successfully.</p>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}