import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Search, Phone, WandSparkles, Clock, Download, Filter, ChevronDown, ChevronUp, User, MapPin, Mail } from 'lucide-react'
import axios from 'axios'
import axiosApi from '../api/axiosApi'
import { useParams } from 'react-router-dom'

const dummyReports = [
    { id: 1, title: 'Report 1', date: '2023-05-15', status: 'completed' },
    { id: 2, title: 'Report 2', date: '2023-05-16', status: 'pending' },
    { id: 3, title: 'Report 3', date: '2023-05-17', status: 'completed' },
    { id: 4, title: 'Report 4', date: '2023-05-18', status: 'pending' },
]

export default function AssociateReports() {
    const [associateData, setAssociateData] = useState(null)
    // param id
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [vehicleNumber, setVehicleNumber] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [showFilters, setShowFilters] = useState(false)
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [status, setStatus] = useState('')
    const [search, setSearch] = useState('')

    useEffect(() => {
        fetchAssociateDetails()
    }, [])

    const fetchAssociateDetails = async () => {
        try {
            const response = await axiosApi.post(`/getAssociateDetails?associateId=${id}`)
            setAssociateData(response.data.data)
            setLoading(false)
        } catch (err) {
            setError('Failed to fetch associate details')
            setLoading(false)
        }
    }

    const filteredReports = dummyReports.filter(report => {
        return (
            report.title.toLowerCase().includes(search.toLowerCase()) &&
            (status === '' || report.status === status) &&
            (dateFrom === '' || report.date >= dateFrom) &&
            (dateTo === '' || report.date <= dateTo)
        )
    })

    if (loading) return <div>Loading...</div>
    if (error) return <div>Error: {error}</div>

    return (
        <motion.div
            className="bg-white shadow-lg rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="px-6 py-4 bg-lime-600 text-white">
                <h3 className="text-xl font-semibold">Associate Reports</h3>
            </div>
            <div className="p-6">
                {/* Associate Details Section */}
                <div className="mb-8 bg-lime-50 rounded-lg p-4">
                    <h4 className="text-lg font-semibold mb-4">Associate Details</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="flex items-center">
                            <User className="text-lime-600 mr-3" size={24} />
                            <div>
                                <p className="font-semibold">{associateData.name}</p>
                                <p className="text-sm text-gray-600">Name</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <Phone className="text-lime-600 mr-3" size={24} />
                            <div>
                                <p className="font-semibold">{associateData.mobile}</p>
                                <p className="text-sm text-gray-600">Phone</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <Mail className="text-lime-600 mr-3" size={24} />
                            <div>
                                <p className="font-semibold">{associateData.email}</p>
                                <p className="text-sm text-gray-600">Email</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <MapPin className="text-lime-600 mr-3" size={24} />
                            <div>
                                <p className="font-semibold">{associateData.address}, {associateData.city}, {associateData.state}</p>
                                <p className="text-sm text-gray-600">Location</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${associateData.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                            }`}>
                            {associateData.status}
                        </span>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div className="relative">
                        <Search className="absolute top-3 left-3 text-gray-400" size={20} />
                        <input
                            type="text"
                            placeholder="Vehicle Number"
                            value={vehicleNumber}
                            onChange={(e) => setVehicleNumber(e.target.value)}
                            className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                        />
                    </div>
                    <div className="relative">
                        <Phone className="absolute top-3 left-3 text-gray-400" size={20} />
                        <input
                            type="tel"
                            placeholder="Mobile Number"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                        />
                    </div>
                </div>
                <motion.button
                    className="w-full bg-lime-600 text-white py-2 px-4 rounded-lg hover:bg-lime-700 transition duration-300"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    View Reports
                </motion.button>

                <div className="mt-8">
                    <div className="flex justify-between items-center mb-4">
                        <h4 className="text-lg font-semibold">Reports</h4>
                        <motion.button
                            onClick={() => setShowFilters(!showFilters)}
                            className="flex items-center text-lime-600 hover:text-lime-800"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Filter size={20} className="mr-2" />
                            Filters
                            {showFilters ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </motion.button>
                    </div>

                    <AnimatePresence>
                        {showFilters && (
                            <motion.div
                                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6"
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                            >
                                <input
                                    type="date"
                                    placeholder="From Date"
                                    value={dateFrom}
                                    onChange={(e) => setDateFrom(e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                />
                                <input
                                    type="date"
                                    placeholder="To Date"
                                    value={dateTo}
                                    onChange={(e) => setDateTo(e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                />
                                <select
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                >
                                    <option value="">All Statuses</option>
                                    <option value="completed">Completed</option>
                                    <option value="pending">Pending</option>
                                </select>
                                <input
                                    type="text"
                                    placeholder="Search reports"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <div className="space-y-4">
                        <AnimatePresence>
                            {filteredReports.map((report) => (
                                <motion.div
                                    key={report.id}
                                    className="flex items-center justify-between border-b pb-4"
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <div className="flex items-start">
                                        <WandSparkles className="text-lime-600 mr-3" size={24} />
                                        <div>
                                            <h5 className="font-semibold text-lg">{report.title}</h5>
                                            <p className="text-sm text-gray-600">Date: {report.date}</p>
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${report.status === 'completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                                                }`}>
                                                <Clock className="mr-1" size={12} />
                                                {report.status}
                                            </span>
                                        </div>
                                    </div>
                                    <motion.button
                                        className="flex items-center text-lime-600 hover:text-lime-800"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <Download className="mr-1" size={16} />
                                        <span className="text-sm">Download</span>
                                    </motion.button>
                                </motion.div>
                            ))}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}