import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import { Sun, Moon, LogOut } from 'lucide-react';

const Navbar = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { user, logout } = useAuth();

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold text-lime-600 dark:text-lime-400">
              {user && user.role === 'admin' ? 'Admin Panel' : 'Dealer Panel'}
            </Link>
          </div>
          <div className="flex items-center">
            {user && (
              <>
                {user.role === 'admin' && (
                  <>
                    <Link to="/dealers" className="text-gray-700 dark:text-gray-300 hover:text-lime-600 dark:hover:text-lime-400 px-3 py-2 rounded-md text-sm font-medium">
                      Dealers
                    </Link>
                    {/* <Link to="/associates" className="text-gray-700 dark:text-gray-300 hover:text-lime-600 dark:hover:text-lime-400 px-3 py-2 rounded-md text-sm font-medium">
                      Associates
                    </Link> */}
                  </>
                )}
                {user.role === 'dealer' && (
                  <Link to={`/dealers/${user.id}`} className="text-gray-700 dark:text-gray-300 hover:text-lime-600 dark:hover:text-lime-400 px-3 py-2 rounded-md text-sm font-medium">
                    My Profile
                  </Link>
                )}
                <button
                  onClick={logout}
                  className="text-gray-700 dark:text-gray-300 hover:text-lime-600 dark:hover:text-lime-400 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                >
                  <LogOut size={20} className="mr-2" />
                  Logout
                </button>
              </>
            )}
            <button
              onClick={toggleTheme}
              className="ml-4 p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
            >
              {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;