import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { Plus, Trash2, User, Search, Filter, ChevronDown, ChevronUp, ExternalLink, Loader } from 'lucide-react'
import ConfirmationDialog from './ConfirmationDialog'
import axiosApi from '../api/axiosApi'

export default function AssociatesManagement() {
    const navigate = useNavigate()
    const [associates, setAssociates] = useState([])
    const [deleteConfirmation, setDeleteConfirmation] = useState({ isOpen: false, associateId: null, parent_id: null })
    const [showFilters, setShowFilters] = useState(false)
    const [filters, setFilters] = useState({
        status: '',
        location: '',
        dealer: '',
        search: '',
    })
    const [isDeleting, setIsDeleting] = useState(false)

    useEffect(() => {
        fetchAssociates()
    }, [])



    const fetchAssociates = async () => {
        try {
            const response = await axiosApi.post('/getAllAssociates')
            const associatesData = response.data.data;
            setAssociates(associatesData)
        } catch (error) {
            console.error('Error fetching associates:', error)
        }
    }

    const handleRemoveAssociate = (id, parent_id) => {
        setDeleteConfirmation({ isOpen: true, associateId: id, parent_id: parent_id })
    }

    const confirmDelete = async () => {
        if (deleteConfirmation.associateId) {
            setIsDeleting(true)
            try {
                await axiosApi.post(`/deleteUser?userToDelete=${deleteConfirmation.associateId}&parent_id=${deleteConfirmation.parent_id}`)
                setAssociates(associates.filter((associate) => associate.id !== deleteConfirmation.associateId))
            } catch (error) {
                console.error('Error deleting associate:', error)
                // Handle error (e.g., show error message to user)
            } finally {
                setIsDeleting(false)
                setDeleteConfirmation({ isOpen: false, associateId: null, parent_id: null })
            }
        }
    }

    const handleFilterChange = (e) => {
        const { name, value } = e.target
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }))
    }

    const filteredAssociates = associates.filter(associate => {
        return (
            (filters.status === '' || associate.status === filters.status) &&
            (filters.location === '' || associate.city.toLowerCase().includes(filters.location.toLowerCase())) &&
            (filters.dealer === '' || (associate.dealer && associate.dealer.toLowerCase().includes(filters.dealer.toLowerCase()))) &&
            (filters.search === '' ||
                associate.name.toLowerCase().includes(filters.search.toLowerCase()) ||
                associate.email.toLowerCase().includes(filters.search.toLowerCase()))
        )
    })

    const handleDealerClick = (dealerId) => {
        navigate(`/dealers/${dealerId}`)
    }

    return (
        <motion.div
            className="bg-white shadow-lg rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="px-6 py-4 bg-lime-600 text-white flex justify-between items-center">
                <h3 className="text-xl font-semibold">Associates Management</h3>
                <motion.button
                    onClick={() => navigate('/associates/new')}
                    className="bg-white text-lime-600 font-bold py-2 px-4 rounded-full flex items-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <Plus size={20} className="mr-2" />
                    Add Associate
                </motion.button>
            </div>
            <div className="p-6">
                <div className="mb-6">
                    <div className="flex justify-between items-center mb-4">
                        <div className="relative flex-grow mr-4">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search associates..."
                                value={filters.search}
                                onChange={handleFilterChange}
                                className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                            />
                        </div>
                        <motion.button
                            onClick={() => setShowFilters(!showFilters)}
                            className="flex items-center text-lime-600 hover:text-lime-800"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Filter size={20} className="mr-2" />
                            Filters
                            {showFilters ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </motion.button>
                    </div>
                    <AnimatePresence>
                        {showFilters && (
                            <motion.div
                                className="grid grid-cols-1 md:grid-cols-3 gap-4"
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                            >
                                <select
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                >
                                    <option value="">All Statuses</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                                <input
                                    type="text"
                                    name="location"
                                    placeholder="Filter by location"
                                    value={filters.location}
                                    onChange={handleFilterChange}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                />
                                <input
                                    type="text"
                                    name="dealer"
                                    placeholder="Filter by dealer"
                                    value={filters.dealer}
                                    onChange={handleFilterChange}
                                    className="w-full px-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <AnimatePresence>
                    {filteredAssociates.map((associate) => (
                        <motion.div
                            key={associate.id}
                            className="bg-gray-50 rounded-lg p-4 mb-4 flex justify-between items-center"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className="flex items-center">
                                <User size={40} className="text-lime-600 mr-4" />
                                <div>
                                    <h4 className="text-lg font-semibold text-gray-800">{associate.name}</h4>
                                    <p className="text-sm text-gray-600">{associate.email}</p>
                                    <div className="flex space-x-2 mt-1">
                                        <span className="inline-block px-2 py-1 text-xs font-semibold rounded-full bg-green-200 text-green-800">
                                            Active
                                        </span>
                                        <span className="inline-block px-2 py-1 text-xs font-semibold rounded-full bg-blue-200 text-blue-800">
                                            {associate.city}
                                        </span>
                                        {associate.parent_id && (
                                            <motion.span
                                                className="inline-flex items-center px-2 py-1 text-xs font-semibold rounded-full bg-purple-200 text-purple-800 cursor-pointer"
                                                onClick={() => handleDealerClick(associate.parent_id)}
                                                whileHover={{ scale: 1.05 }}
                                                whileTap={{ scale: 0.95 }}
                                            >
                                                Dealer ID: {associate.parent_id}
                                                <ExternalLink size={12} className="ml-1" />
                                            </motion.span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <motion.button
                                onClick={() => handleRemoveAssociate(associate.id, associate.parent_id)}
                                className="text-red-600 hover:text-red-800"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                disabled={isDeleting}
                            >
                                <Trash2 size={20} />
                            </motion.button>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>
            <ConfirmationDialog
                isOpen={deleteConfirmation.isOpen}
                onClose={() => setDeleteConfirmation({ isOpen: false, associateId: null })}
                onConfirm={() => confirmDelete()}
                title="Delete Associate"
                message="Are you sure you want to delete this associate? This action cannot be undone."
                isLoading={isDeleting}
                confirmText={isDeleting ? 'Deleting...' : 'Delete'}
                confirmIcon={isDeleting ? Loader : Trash2}
            />
        </motion.div>
    )
}