import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { Plus, Trash2, Edit2, Users, FileText, DollarSign, Search, Calendar, Eye, Briefcase, Loader } from 'lucide-react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from 'chart.js'
import { Pie, Line, Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import ConfirmationDialog from './ConfirmationDialog'
import axiosApi from '../api/axiosApi'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement)

export default function DealersManagement() {
    const navigate = useNavigate()
    const [dealers, setDealers] = useState([])
    const [totalAssociates, setTotalAssociates] = useState(0)
    const [totalReports, setTotalReports] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [deleteConfirmation, setDeleteConfirmation] = useState({ isOpen: false, dealerId: null, parent_id: null })
    const [isDeleting, setIsDeleting] = useState(false)

    useEffect(() => {
        fetchDealers()
    }, [])

    const fetchDealers = async () => {
        try {
            const response = await axiosApi.get('/getAllUserByParent')
            const dealersData = response.data.data.filter(user => user.role === 'dealer')
            // Also filter associates by role === 'associate'
            const associatesData = response.data.data.filter(user => user.role === 'associate')

            setTotalAssociates(associatesData.length)

            // Fetch associate count for each dealer
            const dealersWithAssociates = await Promise.all(dealersData.map(async (dealer) => {
                const associatesResponse = await axiosApi.get(`/getAssociateByDealer?dealerId=${dealer.id}`)
                return {
                    ...dealer,
                    associateCount: associatesResponse.data.data.length
                }
            }))

            setDealers(dealersWithAssociates)

            // Calculate total associates
            const totalAssociatesCount = dealersWithAssociates.reduce((sum, dealer) => sum + dealer.associateCount, 0)
            setTotalAssociates(totalAssociatesCount)

        } catch (error) {
            console.error('Error fetching dealers:', error)
        }
    }

    const handleRemoveDealer = (id, parent_id) => {
        console.log('handleRemoveDealer')
        console.log(id)
        console.log(parent_id)
        setDeleteConfirmation({ isOpen: true, dealerId: id, parent_id: parent_id })

    }

    const confirmDelete = async () => {


        console.log(deleteConfirmation)
        console.log(dealers)
        console.log(totalAssociates)

        if (deleteConfirmation.dealerId) {
            setIsDeleting(true)
            try {
                await axiosApi.post(`/deleteUser?userToDelete=${deleteConfirmation.dealerId}&parent_id=${deleteConfirmation.parent_id}`)
                setDealers(dealers.filter((dealer) => dealer.id !== deleteConfirmation.dealerId))

                const updatedTotalAssociates = dealers.reduce((sum, dealer) => {
                    return dealer.id !== deleteConfirmation.dealerId ? sum + dealer.associateCount : sum
                }, 0)
                setTotalAssociates(updatedTotalAssociates)
            } catch (error) {
                console.error('Error deleting dealer:', error)

            } finally {
                setIsDeleting(false)
                setDeleteConfirmation({ isOpen: false, dealerId: null })
            }
        }
    }

    const pieChartData = {
        labels: dealers.map(dealer => dealer.name),
        datasets: [
            {
                data: dealers.map(dealer => dealer.associateCount),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                ],
            },
        ],
    }

    const lineChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: dealers.map((dealer, index) => ({
            label: dealer.name,
            data: Array(6).fill().map(() => Math.floor(Math.random() * 100)),
            borderColor: `hsl(${index * 90}, 70%, 50%)`,
            tension: 0.1,
        })),
    }

    const barChartData = {
        labels: dealers.map(dealer => dealer.name),
        datasets: [
            {
                label: 'Total Reports',
                data: dealers.map(dealer => dealer.totalReports),
                backgroundColor: 'rgba(75, 192, 192, 0.8)',
            },
        ],
    }

    const filteredDealers = dealers.filter(dealer =>
        dealer.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!startDate || new Date(dealer.createdAt) >= new Date(startDate)) &&
        (!endDate || new Date(dealer.createdAt) <= new Date(endDate))
    )

    return (
        <motion.div
            className="bg-white shadow-lg rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="px-6 py-4 bg-lime-600 text-white flex justify-between items-center">
                <h3 className="text-xl font-semibold">Dealers Management</h3>
                <motion.button
                    onClick={() => navigate('/dealers/new')}
                    className="bg-white text-lime-600 font-bold py-2 px-4 rounded-full flex items-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <Plus size={20} className="mr-2" />
                    Add Dealer
                </motion.button>
            </div>
            <div className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                    <div className="bg-purple-100 p-4 rounded-lg flex items-center justify-between">
                        <div>
                            <p className="text-purple-800 font-semibold">Total Dealers</p>
                            <h4 className="text-2xl font-bold text-purple-900">{dealers.length}</h4>
                        </div>
                        <Briefcase size={40} className="text-purple-500" />
                    </div>
                    <div className="bg-blue-100 p-4 rounded-lg flex items-center justify-between">
                        <div>
                            <p className="text-blue-800 font-semibold">Total Associates</p>
                            <h4 className="text-2xl font-bold text-blue-900">{totalAssociates}</h4>
                        </div>
                        <Users size={40} className="text-blue-500" />
                    </div>
                    <div className="bg-green-100 p-4 rounded-lg flex items-center justify-between">
                        <div>
                            <p className="text-green-800 font-semibold">Total Reports</p>
                            <h4 className="text-2xl font-bold text-green-900">{totalReports}</h4>
                        </div>
                        <FileText size={40} className="text-green-500" />
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h4 className="text-lg font-semibold mb-2">Associates Distribution</h4>
                        <Pie data={pieChartData} />
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h4 className="text-lg font-semibold mb-2">Monthly Reports Trend</h4>
                        <Line data={lineChartData} />
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h4 className="text-lg font-semibold mb-2">Total Reports by Dealer</h4>
                        <Bar data={barChartData} />
                    </div>
                </div>

                <div className="mb-6 flex space-x-4">
                    <div className="relative flex-1">
                        <Search className="absolute top-3 left-3 text-gray-400" size={20} />
                        <input
                            type="text"
                            placeholder="Search dealers..."
                            className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <div className="relative">
                            <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="date"
                                className="pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="relative">
                            <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                            <input
                                type="date"
                                className="pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-lime-500"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <h4 className="text-lg font-semibold mb-4">Dealer List</h4>
                <AnimatePresence>
                    {filteredDealers.map((dealer) => (
                        <motion.div
                            key={dealer.id}
                            className="bg-gray-50 rounded-lg p-4 mb-4 flex justify-between items-center"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div>
                                <h4 className="text-lg font-semibold text-gray-800">{dealer.name}</h4>
                                <p className="text-sm text-gray-600">Email: {dealer.email}</p>
                                <p className="text-sm text-gray-600">Mobile: {dealer.mobile}</p>
                                <p className="text-sm text-gray-600">Location: {dealer.city}, {dealer.state}</p>
                                <p className="text-sm text-gray-600">Joined: {new Date(dealer.createdAt).toLocaleDateString()}</p>
                                <p className="text-sm text-gray-600">Associates: {dealer.associateCount}</p>
                            </div>
                            <div className="flex space-x-2 items-center">
                                <Link
                                    to={`/dealers/${dealer.id}`}
                                    className="text-lime-600 hover:text-lime-800"
                                >
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        className="p-1"
                                    >
                                        <Eye size={20} />
                                    </motion.button>
                                </Link>
                                <motion.button
                                    onClick={() => handleRemoveDealer(dealer.id, dealer.parent_id)}
                                    className="text-red-600 hover:text-red-800 p-1"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    disabled={isDeleting}
                                >
                                    <Trash2 size={20} />
                                </motion.button>
                            </div>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>
            <ConfirmationDialog
                isOpen={deleteConfirmation.isOpen}
                onClose={() => setDeleteConfirmation({ isOpen: false, dealerId: null })}
                onConfirm={() => confirmDelete()}
                title="Delete Dealer"
                message="Are you sure you want to delete this dealer? This action cannot be undone."
                isLoading={isDeleting}
                confirmText={isDeleting ? 'Deleting...' : 'Delete'}
                confirmIcon={isDeleting ? Loader : Trash2}
            />
        </motion.div>
    )
}